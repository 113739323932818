<template>
  <div :class="classList.modal">
    <div :class="classList.modalDialog">
      <div :class="classList.modalContent">
        <div :class="classList.modalHeader">
          <h5 :class="classList.modalTitle">
            {{ t('title') }}
          </h5>
        </div>
        <div :class="classList.modalBody">
          <span>{{ t('line_1') }}</span>
          <span>
            {{ t('line_2') }}
          </span>
          <div class="csn-store-update-button-container">
            <button
              class="btn casino-btn casino-btn-default casino-btn-main-menu"
              @click="handleButtonClick"
            >
              {{ t('update') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  EventType,
  STORE_UPDATE_MODAL,
  MODAL_DIALOG,
  MODAL_CONTENT,
  MODAL_HEADER,
  MODAL_TITLE,
  MODAL_BODY,
} from '@/constants'
import { updateStoreVersion } from '@/helpers'

const CSN_STORE_UPDATE = 'csn-store-update'

export default {
  name: STORE_UPDATE_MODAL,
  computed: {
    classList: () => ({
      modal: CSN_STORE_UPDATE,
      modalDialog: MODAL_DIALOG,
      modalContent: MODAL_CONTENT,
      modalHeader: MODAL_HEADER,
      modalTitle: MODAL_TITLE,
      modalBody: MODAL_BODY,
    }),
    t() {
      return this.$createComponentTranslator(STORE_UPDATE_MODAL)
    },
  },
  methods: {
    closeModalAndReload() {
      this.$emit(EventType.CLOSE, window.location.reload)
    },
    handleButtonClick() {
      updateStoreVersion(this.closeModalAndReload)
    },
  },
}
</script>
